import React, { Component } from "react";
import Link from "../components/link";
import Isvg from "react-inlinesvg";
import Page from "../containers/page";
import article from "../assets/images/article.png";
import { API_ENDPOINT } from "../constants";
import moment from "moment";
import arrowBack from "../assets/images/arrow_back.png";
import SupportForm from "../components/forms/supportForm2";
import chat from "../assets/images/chat.png";
import ChatForm from "../components/forms/chatForm11";
import ContactForm from '../components/forms/contactForm';

function parseJSON(response) {
  return response.json().then(json => {
    return {
      result: json,
      status: response.status
    }
  })
}
class NewsDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      promotedProjects: [],
      poolActive: false,
      chatForm: false,
      ...props.initialData,
      contactFormData: {}
    };
  }


  get = () => {

    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

    for (let i = 0; i < this.props.loadData.length; i++) {
      this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
        this.setState({
          ...data,
          loading: null
        }, () => {
          this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        })
      })
    }

    for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {

      this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match).then((data) => {
        this.setState({
          ...data,
          loading: null
        }, () => {
        
          document.title = this.state?.result?.titleSeoNews?.[this.props.lang]
          this.props.updateMeta(this.props.generateSeoTags(this.state.result, this.props.lang));
        })
      })
    }

  }

  componentDidMount() {
    if (typeof window !== "undefined") {
      window.scrollTo(0, 0);
    }

    // console.log(this.state);

    this.props.updateMeta(
      this.props.generateSeoTags(this.state, this.props.lang)
    );
    // console.log(this.props[0].location.state, 'news details state', this.props[0].location.state.article.pageNumber);
    
    if (this.props[0].location && this.props[0].location.state && this.props[0].location.state.article) {
    
      this.setState({
        article: this.props[0].location.state.article,
        pageNumber: this.props[0].location.state.article.pageNumber ? this.props[0].location.state.article.pageNumber : 0
      }, () => {
        this.get()
      });
    }


    if (!this.state.article && this.props[0].location.pathname) {
   
      fetch(API_ENDPOINT + '/data/pages/news-details', {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ title: this.props[0].location.pathname })
      }).then(parseJSON).then(({ result, status }) => {

        console.log('result: ', result)

        if (result.error) {
          // this.setState({ error: result.error })
          this.props[0].history.push("/")
        } else {
          this.setState({
            article: result
          }, () => {
            this.get()
          })
        }
      })
    }

    fetch(API_ENDPOINT + '/data/pages/news/contact', {
      method: 'GET',
      headers: {
        'content-type': 'application/json'
      },
    }).then(parseJSON).then(({ result, status }) => {

      if (result.error) {
        // this.setState({ error: result.error })
        this.props[0].history.push("/")
      } else {
        this.setState({
          contactFormData: result.newsContactForm
        })
      }
    })


  }

  sendMessage1 = (data) => {
    if (data.checked) {
      data.lang = this.props.lang
      this.setState({
        loading1: true
      }, () => {

        fetch(API_ENDPOINT + '/data/contact/new', {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({ data })
        }).then(parseJSON).then(({ result, status }) => {
          if (result.error) {
            this.setState({ error: result.error })
          } else {
          }
        })
      })
    }
  }

  sendMessage2 = (data) => {
    this.props.setRecaptchaCallback(() => {

      if (data && data.checked && this.props.allowSubmit) {
        if (data.email && (data.email.toLowerCase().indexOf('@gmail.com') !== -1 || data.email.toLowerCase().indexOf('@live.com') !== -1 || data.email.toLowerCase().indexOf('@mailinator.com') !== -1 || data.email.toLowerCase().indexOf('@jollyfree.com') !== -1 || data.email.toLowerCase().indexOf('@gotgel.org') !== -1 || data.email.toLowerCase().indexOf('@yahoo.com') !== -1 || data.email.toLowerCase().indexOf('@hotmail.com') !== -1 || data.email.toLowerCase().indexOf('@business.com') !== -1 || data.email.toLowerCase().indexOf('@admin.com') !== -1 || data.email.toLowerCase().indexOf('@info.com') !== -1)) {
          return this.setState({ contactFormError: 'Please fill in your work email' })
        } else this.setState({ contactFormError: null })

        // if (data.message?.length < 25) return this.setState({ contactFormMessageError: 'Please use at least 25 letters' });
        // else this.setState({ contactFormMessageError: null })
        data.url = typeof window !== 'undefined' ? window.location?.pathname : 'No Data';
        data.lang = this.props.lang;
        data.token = this.props.recaptchaToken
        data.isMobile = this.props.isMobile
        data.osMobileNameUA = this.props.osMobileNameUA
        data. browserName = this.props.browserName,
        data.browserNameUA = this.props.browserNameUA
        this.setState(
          {
            loading2: true,
          },
          () => {
            setTimeout(() => {
              this.player.current.pause();
            }, 1120);

            fetch(API_ENDPOINT + "/data/contact/new", {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({ data }),
            })
              .then(parseJSON)
              .then(({ result, status }) => {
                if (status === 429) {
                  this.setState({
                    solutionsStatus: true,
                    loading2: false,
                  });
                }
                if (result.error) {
                  this.setState({ error: result.error });
                } else {
                  this.props.bookDemo(false);

                  const path = "thank-you";
                  this.props[0].history.push(`/${path}`);
                }
              });
          }
        );
      }
    })
    this.props.updateToken();

  };

  // componentDidUpdate(prevProps) {
  //   if (prevProps[0].location.pathname != this.props[0].location.pathname) {
  //     // this.get()
  //   }


  // }

  render() {

    return (
      <div>

        <div
          className={
            this.props.openModalBookDemo
              ? "contact-popup open"
              : "contact-popup"
          }
        >
          <span
            className="close-icon"
            onClick={() => {
              this.props.bookDemo(false);
            }}
          >
            x
          </span>
          {!this.state.loading3 && !this.state.supportStatus ? (
            <SupportForm onSubmit={this.sendMessage2} lang={this.props.lang} />
          ) : null}
          {this.state.loading3 ? (
            <Player
              ref={this.player}
              autoplay={true}
              onEvent={(event) => {
                if (event == "complete") {
                  this.setState({
                    supportForm: false,
                    loading3: false,
                  });
                }
              }}
              //keepLastFrame={true}
              //loop={false}
              controls={true}
              src="https://assets4.lottiefiles.com/packages/lf20_oaw8d1yt.json"
              style={{ height: "300px", width: "300px" }}
            ></Player>
          ) : null}
          {this.state.supportStatus && (
            <h2 className="status-popup support-status">
              {"Please, try again later!".translate(this.props.lang)}
            </h2>
          )}
        </div>

        <div className="news-detials">
          {this.state.article ? (
            <div className="news-detials-content">
              <div className="container-curoflow news-details-bigImage">
                <img
                  src={API_ENDPOINT + this.state.article.backgroundImage}
                  alt={this.state.article.backgroundImage
                    .replace("/uploads/", "")
                    ?.replace(/_/g, " ")
                    ?.slice(0, -4)}
                />
              </div>
              <div className="news-details-content">
                <Link lang={this.props.lang} to={'/nyheter-om-curoflow-vardplattform'} state={{pageNumber: this.state.pageNumber ? this.state.pageNumber : 0}} className="news-link">
                  <img src={arrowBack} />{" "}
                  {"More news".translate(this.props.lang)}
                </Link>
                <span className="time">
                  {this.state.article && this.state.article.date ? this.state.article.date :
                    moment
                      .unix(this.state.article && this.state.article.tsCreated)
                      .format(`YYYY-MM-DD`)}
                </span>
                <h2>{this.state.article.title[this.props.lang]}</h2>


                <div className="introduction"
                  dangerouslySetInnerHTML={{
                    __html: this.state.article && this.state.article.shortDescription
                      ? this.state.article && this.state.article.shortDescription[this.props.lang]
                      : "",
                  }}
                ></div>


                <div
                  dangerouslySetInnerHTML={{
                    __html: this.state.article && this.state.article.description
                      ? this.state.article && this.state.article.description[this.props.lang]
                      : "",
                  }} className="articlesNew"
                ></div>
              </div>

            </div>
          ) : null}


          {
            this.state.contactFormData ?

              <ContactForm title={this.state.contactFormData.title && this.state.contactFormData.title[this.props.lang]} text={this.state.contactFormData.shortDescription && this.state.contactFormData.shortDescription[this.props.lang]} class={'grey'}  onSubmit={this.sendMessage2} lang={this.props.lang} contactFormError={this.state.contactFormError} contactFormMessageError={this.state.contactFormMessageError} />

              :
              null
          }
        </div>
      </div>
    );
  }
}

export default Page(NewsDetails);
